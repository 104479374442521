
import { Component } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

@Component({
  name: 'EditableTable',
  inheritAttrs: false
})
export default class EditableTable extends VueStrong {
  selectedTableKeys: any[] = [...this.$attrs['headers']].map((item: any) => item.value)
  availableTableKeys: any[] = [...this.$attrs['headers']]

  get filteredAttrs() {
    const headers = this.availableTableKeys.filter(item => this.selectedTableKeys.includes(item.value))
    return { ...this.$attrs, headers }
  }
}
