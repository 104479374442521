
import { Component, Prop } from 'vue-property-decorator'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, GridComponent } from 'echarts/components'
import VChart from 'vue-echarts'
import VueStrong from '@/VueStrong'

import { use } from 'echarts/core'
use([CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, GridComponent, LineChart])
@Component({
  components: {
    'v-chart': VChart
  }
})
export default class SeriesLineChart extends VueStrong {
  @Prop({ required: true }) title: string
  @Prop({ required: true }) series: {
    name: string
    type: 'line'
    stack: 'Total'
    data: number[]
  }[]

  @Prop({ required: true }) xAxisData: string[]

  get noData() {
    return !this.xAxisData || !this.xAxisData.length
  }

  get options() {
    return {
      title: {
        text: this.title
      },
      xAxis: {
        type: 'category',
        data: this.xAxisData
      },
      yAxis: {
        type: 'value'
      },
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        left: '15%'
      },
      series: this.series
    }
  }
}
