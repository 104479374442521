
import EditableTable from '@/components/EditableTable.vue'
import SeriesLineChart from '@/components/SeriesLineChart.vue'
import SmoothedLineChart from '@/components/SmoothedLineChart.vue'
import OverviewInsightsFilters from '@/modules/insight/components/OverviewInsightsFilters.vue'
import { Component } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import ComingSoonBanner from '@/components/ComingSoonBanner.vue'

@Component({
  components: { OverviewInsightsFilters, SmoothedLineChart, SeriesLineChart, EditableTable, ComingSoonBanner }
})
export default class OverviewInsights extends VueStrong {
  headers = [
    { value: 'date', sortable: false, text: 'Date' },
    { value: 'adSpend', sortable: false, text: 'Ad Spend' },
    { value: 'revenue', sortable: false, text: 'Revenue ($)' },
    { value: 'shippingCost', sortable: false, text: 'Shipping ($)' },
    { value: 'refunds', sortable: false, text: 'Refunds ($)' },
    { value: 'productCost', sortable: false, text: 'COG ($)' },
    { value: 'profit', sortable: false, text: 'Profit ($)' },
    { value: 'margin', sortable: false, text: 'Margin (%)' },
    { value: 'roi', sortable: false, text: 'ROI' },
    { value: 'roas', sortable: false, text: 'ROAS' },
    { value: 'orderCount', sortable: false, text: 'Orders' },
    { value: 'refundRate', sortable: false, text: 'Refund %' }
  ]

  get profitChartData() {
    return {
      xAxisData: this.overviewData.map(item => item.date),
      yAxisData: this.overviewData.map(item => item.profit)
    }
  }

  get seriesChartData() {
    const keys = ['revenue', 'adSpend', 'productCost', 'shippingCost', 'refunds']

    return {
      xAxisData: this.overviewData.map(item => item.date),
      series: [
        ...keys.map(k => ({
          name: k,
          type: 'line',
          smooth: true,
          stack: 'Total',
          data: this.overviewData.map(item => (k === 'revenue' ? item[k] : -item[k]))
        })),
        {
          name: 'profit',
          type: 'line',
          data: this.overviewData.map(item => item.profit),
          smooth: true,
          lineStyle: {
            normal: {
              width: 4
            }
          }
        }
      ]
    }
  }

  get totalProfit() {
    return this.totalRevenue - this.totalExpenses - this.totalAdSpend
  }

  get totalAdSpend() {
    return this.overviewData.reduce((acc, item) => acc + item.adSpend, 0)
  }

  get totalRevenue() {
    return this.overviewData.reduce((acc, item) => acc + item.revenue, 0)
  }

  get totalExpenses() {
    return this.overviewData.reduce((acc, item) => acc + item.productCost + item.shippingCost + item.refunds, 0)
  }

  get totalROI() {
    return (this.totalProfit / this.totalAdSpend) * 100
  }

  get totalROAS() {
    return (this.totalRevenue / this.totalAdSpend) * 100
  }

  get overviewData() {
    return this.$store.state.insight ? this.$store.state.insight.overviewData || [] : []
  }

  // overviewSegment.margin = parseFloat(((overviewSegment.profit / overviewSegment.revenue) * 100)) || 0
  get margin() {
    return (this.totalProfit / this.totalRevenue) * 100
  }

  get userCanAccess() {
    if (!this.user) return false
    return this.user.privileges! > 1 || (this.user.additionalScopes || []).includes('marketing-insights')
  }

  get user() {
    return this.$store.state.account.user
  }
}
