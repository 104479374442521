
import { Component } from 'vue-property-decorator'
import FiltersMixin from '@/modules/insight/mixins/FiltersMixin.vue'
import moment from 'moment'

@Component({ mixins: [FiltersMixin] })
export default class OverviewInsightsFilters extends FiltersMixin {
  date = ''
  dates = []
  groupBy: 'week' | 'day' | 'month' = 'week'
  modal = false

  paramsField = 'overviewParams'
  setFilterAction = 'insight/setOverviewParams'
  getAction = 'insight/getOverviewInsights'

  initialParams = {
    start: this.formatDate(moment().subtract(7, 'day')),
    end: this.formatDate(moment()),
    groupBy: 'week'
  }

  created() {
    this.getData()
  }
}
